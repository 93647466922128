.p5Canvas {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
  /* margin-left: 15% !important; */
  align-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  /* transition: width 100ms !important; */
}
/* .p5-react {
  width: 100% !important;
  height: 100% !important;
} */
