.filter {
  margin: 0;
  padding: 0;
  align-content: center;
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-inline-start: 30%;
}

.my-btn {
  background: #fff;
  color: #222;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #ddd;
  border-radius: 2px;
  padding: 4px 17px;
  font-size: 11px;
  margin: 2px;
}

.my-btn-clicked {
  background: #55a8dd;
  color: #fff;
  border: 1px solid #e7e7e7;
  border-bottom: 2px solid #ddd;
  border-radius: 2px;
  padding: 4px 17px;
  font-size: 11px;
  margin: 2px;
}
.in-filter {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
