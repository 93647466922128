.tooltip {
  padding: 0;
  button {
    padding: 0.3125rem 0.5625rem;
    appearance: none;
    background: tomato;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    margin:5px;
    cursor: pointer;
    color: white;
    font-size:15px;

    .icon {
      filter: invert(100%);
    }
  }
}