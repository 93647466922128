.navbar {
  padding: 0;
}

.navbar-brand {
  padding: 0;
}

.is-cursor {
  cursor: pointer;
}
