.container {
  position: relative;
  font-family: Arial;
}
.text-block {
  position: absolute;
  /* bottom: 1%;
  right: 20%; */
  background-color: rgb(255, 255, 255);
  /* background-color: rgb(36, 35, 35); */
  /* padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 0; */
  /* padding: 4; */
  margin: 5;
  /* opacity: 0.85; */
  text-align: left;
}
.condensedlines {
  font-size: 10pt;
  line-height: 10pt;
  margin-bottom: 0;
}
